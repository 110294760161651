// Import FirebaseAuth and firebase.
import React, { useContext } from "react";
import styled from "styled-components";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { EventContext, firebase, UserContext, EventAvatar } from "components";
import {
  Select,
  H3,
  H4,
  Link,
  GridColumn,
  Logo,
  Label,
  ButtonPrimary,
} from "notes";
import { useCollection } from "react-firebase-hooks/firestore";
import { LoginForm } from "./LoginForm";
import { useHistory, useRouteMatch } from "react-router-dom";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
};

export const Login = () => {
  const { user } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const history = useHistory();
  const { params } = useRouteMatch(`/:eventId`);
  const handleLogin = () => {
    history.push(`/${params.eventId}`);
  };
  return (
    <Wrapper xCenter>
      {user && (
        <SignOut
          onClick={() => {
            firebase.auth().signOut();
            window.location.reload();
          }}
        >
          Sign Out
        </SignOut>
      )}
      <SetLiveLogo name="SetLiveDefault" />
      <Content xCenter>
        <StyledAvatar />
        <ArtistName>{event?.artist}</ArtistName>
        <H3>Meet &amp; Greet</H3>
        {user ? (
          <GridColumn style={{ width: "100%" }}>
            <Label>Name</Label>
            <H4>{user.displayName}</H4>
            <Label>Email</Label>
            <H4>{user.email}</H4>
            <ButtonPrimary
              style={{ marginTop: "20px" }}
              onClick={() => {
                history.push(`/${params.eventId}`);
              }}
            >
              Continue to Event
            </ButtonPrimary>
            {/* <EventSelector /> */}
          </GridColumn>
        ) : (
          <LoginForm onSuccess={handleLogin} />
        )}
      </Content>

      {!user && (
        <AuthContainer>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </AuthContainer>
      )}
    </Wrapper>
  );
};

const AuthContainer = styled(GridColumn)`
  margin-top: 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    position: absolute;
    bottom: 40px;
    right: 24px;
    width: 100%;
    margin-top: 0;
    max-width: 360px;
  }
`;

const StyledAvatar = styled(EventAvatar)`
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.48);
  margin-bottom: 24px;
`;

const Wrapper = styled(GridColumn)`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const SetLiveLogo = styled(Logo)`
  margin-top: 64px;
  width: 114px;
  height: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 48px;
    width: 76px;
    height: 16px;
  }
`;

const ArtistName = styled(H3)`
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 25px;
    line-height: 32px;
  }
`;

const Content = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  width: 100%;
  max-width: 472px;
  ${Label} {
    color: ${(props) => props.theme.palette.gray.lighter};
  }
  ${H4} + ${Label} {
    margin-top: 16px;
  }
  ${ArtistName} + ${H3} {
    color: ${(props) => props.theme.palette.gray.primary};
    margin-bottom: 40px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: 24px 24px 56px 24px;
    ${ArtistName} + ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;

const EventSelector = () => {
  const [events, l] = useCollection(
    firebase.firestore().collection(`meet_greet`)
  );
  const { event, setEventId } = useContext(EventContext);
  if (l) {
    return null;
  }
  const options = events?.docs?.map((evt) => ({
    id: evt?.id,
    text: evt?.data()?.artist,
    subtext: evt?.id,
  }));
  const selected = options.find((evt) => evt.id === event?.id);
  return (
    <>
      <Label>Select Event</Label>
      <Select
        options={options}
        selected={selected}
        onChange={({ id }) => setEventId(id)}
      />
    </>
  );
};

const SignOut = styled(Link)`
  position: absolute;
  top: 24px;
  right: 40px;
`;
