import React, { createContext, useState, useEffect } from "react";
import { firebase } from "components";
import LogRocket from "logrocket";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useLocation, useHistory } from "react-router";

const getParams = (search) => {
  const parsed = new URLSearchParams(search);

  const params = {
    email: parsed.get("email") || "",
    to: parsed.get("to"),
  };

  return params;
};

export const UserContext = createContext();
export const UserConsumer = UserContext.Consumer;
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${user?.uid}`)
  );

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(setUser);
  }, []);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  const history = useHistory();
  const location = useLocation();
  const [redirectToUrl, setRedirectToUrl] = useState(null);

  useEffect(() => {
    if (user && user?.uid && redirectToUrl) history.push(redirectToUrl);
  }, [user, redirectToUrl]);

  useEffect(() => {
    (async () => {
      if (firebase.auth().isSignInWithEmailLink(location.search)) {
        // Link is a sign-in link, let's try to sign in with it
        const params = getParams(location.search);
        await firebase
          .auth()
          .signInWithEmailLink(params.email, location.search);
        if (params.to) {
          setRedirectToUrl(params.to);
        }
      }
    })();
  }, [location.search, history]);

  const updateName = (name) => {
    firebase
      .firestore()
      .doc(`meet_greet_names/${user.uid}`)
      .set({ value: name });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userLoading: user === undefined,
        name: displayname?.value ?? "",
        updateName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
