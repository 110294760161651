import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebase } from "components";
import styled from "styled-components";
// import Joi from "@hapi/joi";
// import { validate } from "modules";
import {
  Label,
  Input,
  Icon,
  GridColumn,
  ButtonPrimary,
  Link,
  ErrorMessage,
} from "notes";

export const emailLogin = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const LoginForm = ({ onSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [hidePassword, setHidePassword] = useState(true);
  const history = useHistory();

  // const manualLoginSchema = Joi.object({
  //   email: Joi.string()
  //     .email({ tlds: { allow: false } })
  //     .required(),
  //   password: Joi.string().required().min(8),
  // });

  const signInWithEmail = async () => {
    let errors = false; //validate({ email, password }, manualLoginSchema);
    try {
      const { user } = await emailLogin(email, password);
      if (user) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      if (errors) {
        setErrorMessage({
          ...errors,
          message: "The following log in information is required",
        });
      } else {
        setErrorMessage({
          message: "The provided log in details are incorrect",
        });
      }
    }
  };

  return (
    <Form noShrink>
      {!!Object.keys(errorMessage).length > 0 && (
        <ErrorMessage
          style={{ marginBottom: 0, marginTop: "24px" }}
          content={errorMessage?.message}
        />
      )}
      <Label>Email address</Label>
      <Input
        leftIcon={<Icon form name="Email" />}
        placeholder="john@email.com"
        value={email}
        error={!!errorMessage.email}
        onChange={(value) => {
          setEmail(value);
        }}
      />

      <Label>Password</Label>
      <Input
        leftIcon={<Icon form name="Key" />}
        rightIcon={
          <TogglePassword
            onClick={() => setHidePassword(!hidePassword)}
            name={hidePassword ? "Hide" : "Show"}
          />
        }
        type={hidePassword ? "password" : "text"}
        placeholder="Password..."
        value={password}
        error={!!errorMessage.password}
        onChange={(value) => {
          setPassword(value);
        }}
      />

      <PasswordLink
        onClick={() => {
          history.push("/reset");
        }}
      >
        Forgot Password?
      </PasswordLink>

      <LoginButton onClick={signInWithEmail}>Log In</LoginButton>
    </Form>
  );
};

const PasswordLink = styled(Link)`
  display: inline-block;
  font-size: 12px;
  margin-top: 12px;
  width: auto;
`;

const Form = styled(GridColumn)`
  width: 100%;
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${PasswordLink} {
    align-self: flex-start;
  }
`;

const TogglePassword = styled(Icon)`
  margin-top: -4px;
`;

const LoginButton = styled(ButtonPrimary)`
  margin-top: 40px;
`;
