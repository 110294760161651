import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Manager } from "./Manager";
import { Fan } from "./Fan";
import { Artist } from "./Artist";
import { Login } from "./Login";
import { Duplicate } from "./Duplicate";
import { UnsupportedBrowser } from "./UnsupportedBrowser";
import { isSupported } from "twilio-video";
import { EventContext, firebase, UserContext } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const Routes = ({ match }) => {
  const { user, userLoading } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const [role, roleLoading] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event?.id}/roles/${user?.uid}`)
  );

  if (!isSupported) {
    return <UnsupportedBrowser />;
  }

  if (userLoading || roleLoading) {
    return null;
  }

  return (
    <Switch>
      {role?.role === "manager" && (
        <Route path={`${match?.path}/manager`} component={Manager} />
      )}
      {role?.role === "fan" && (
        <Route path={`${match?.path}/fan`} component={Fan} />
      )}
      {role?.role === "artist" && (
        <Route path={`${match?.path}/artist`} component={Artist} />
      )}
      <Route path={`${match?.path}/login`} component={Login} />
      <Route path={`/duplicate`} component={Duplicate} />
      {!role?.role && <Redirect to={match.url} />}
      {role?.role && (
        <Redirect to={`/${match?.params?.eventId}/${role?.role}`} />
      )}
    </Switch>
  );
};
